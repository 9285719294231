'use client'

import { PropsWithChildren } from 'react'
import * as ChannelService from '@channel.io/channel-web-sdk-loader'

interface Props extends React.ComponentPropsWithoutRef<'button'> {
  channelTalkLink: string
  className?: string
}

const CustomChannelTalkButton = ({
  channelTalkLink,
  className = '',
  children,
  ...restProps
}: PropsWithChildren<Props>) => {
  const onHideMessengerHandler = () => {
    ChannelService.resetPage()
    ChannelService.clearCallbacks
  }

  const handleChannelTalk = () => {
    ChannelService.setPage(channelTalkLink)
    ChannelService.openChat()
    ChannelService.onHideMessenger(onHideMessengerHandler)
  }

  return (
    <button
      type="button"
      className={className}
      onClick={handleChannelTalk}
      {...restProps}
    >
      {children}
    </button>
  )
}

export default CustomChannelTalkButton
