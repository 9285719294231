import { useEffect, useRef } from 'react'
import { usePathname } from 'next/navigation'
import clsx from 'clsx'
import { useAtomValue, useSetAtom } from 'jotai'
import { ANDROID_APP_STORE, IOS_APP_STORE, MaxWidthSize } from '@/consts'
import { appBannerAtom } from '@/stores'
import { isWebview } from '@/utils'
import { detectOS, isIOS, isSafari } from '@/utils/deviceDetect'
import MaterialSymbol from '@/v1/MaterialSymbol'

const AppDownloadBanner = () => {
  const timerRef = useRef<ReturnType<typeof setTimeout>>()
  const setIsAppBannerOpen = useSetAtom(appBannerAtom)
  const pathname = usePathname()
  const isAppBannerOpen = useAtomValue(appBannerAtom)
  const isAppleOS = isIOS() || detectOS() === 'macOS'

  const handleAosClick = () => {
    window.location.href = `lunitcare:/${pathname ?? '/'}`

    timerRef.current = setTimeout(() => {
      const newWindow = window.open(ANDROID_APP_STORE, '_blank')
      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed === 'undefined'
      ) {
        window.location.href = ANDROID_APP_STORE
      }
    }, 2000)
  }

  const handleIosClick = () => {
    // 먼저 앱 실행 시도
    window.location.href = 'lunitcare://'
    // 앱이 실행되지 않을 경우, 타임아웃 후 앱스토어로 리디렉션
    timerRef.current = setTimeout(() => {
      window.location.href = IOS_APP_STORE
    }, 2000)
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      clearTimeout(timerRef.current)
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  // 1. 모바일 앱에서는 노출하지 않음
  // 2. iOS Safari에서는 기본 스마트 배너가 표시되므로 노출하지 않음
  // 3. 사용자가 배너를 닫은 경우 노출하지 않음
  if (isWebview() || (isIOS() && isSafari()) || !isAppBannerOpen) return null

  return (
    <div
      className={clsx(
        'bg-grey-100',
        'h-[60px]',
        'flex',
        'justify-center',
        'items-center',
        'pl-md pr-[17px]',
      )}
    >
      <div
        className={clsx(
          MaxWidthSize.md,
          'flex',
          'justify-between',
          'items-center',
          'w-full',
        )}
      >
        <p className="self-center prose-p2 break-keep">
          <b>프리미엄 1:1 암 관리, 루닛케어</b>
        </p>
        <div className="flex">
          <button
            data-ga="topbanner_app"
            onClick={isAppleOS ? handleIosClick : handleAosClick}
            className={clsx(
              'bg-aqua-500',
              'rounded-[8px]',
              'h-9',
              'px-3',
              'flex',
              'items-center',
              'mr-3',
              'min-w-[64px]',
            )}
          >
            <p className="text-white prose-p3">앱 열기</p>
          </button>
          <button onClick={() => setIsAppBannerOpen(false)}>
            <MaterialSymbol size={20} name="close" className="fill-grey-600" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default AppDownloadBanner
