import type { GuideListResponse } from '@/types/guides'
import { isNotNullOrUndefined } from '@/utils'

/**
 * 전체 주제별 암정보 목록 중 중분류 아이디만 취합하여 `{params: {id:string}}[]` 형태로 반환
 * @param data 전체 목차 데이터
 */
export const getMidGuidePathsFromList = (data: GuideListResponse) => {
  return data
    .map((item) => item.children)
    .flat()
    .filter(isNotNullOrUndefined)
    .map((child) => ({
      params: {
        id: child.id.toString(),
      },
    }))
}

// 문자열의 공백문자를 _로 변환한 후, 인코딩한 결과를 반환한다.
export const encodeTextWithoutSpace = (text: string) => {
  return encodeURIComponent(text.split(' ').join('_'))
}

// url fragment의 _를 공백문자로 변환한 후, 디코딩한 결과를 반환한다.
export const decodeTextWithUnderscore = (text: string) => {
  return decodeURIComponent(text.trim().split('_').join(' '))
}

/**
 * 현재 경로에 인코딩된 해시값을 붙여서 반환한다.
 * @param hash 해시값
 * @returns  인코딩된 해시값을 붙인 경로.
 */
export const getUrlWithHash = (hash: string) => {
  if (typeof window === 'undefined') return ''
  const pathname = `${window.location.pathname}${window.location.search}`
  if (!hash) return pathname
  return `${pathname}#${encodeTextWithoutSpace(hash)}`
}
