'use client'

import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useAtomValue } from 'jotai'
import useIsClient from '@/hooks/useIsClient'
import { appBannerAtom } from '@/stores/appBanner'
import { isWebview } from '@/utils'
import { isIOS, isSafari } from '@/utils/deviceDetect'
import AppDownloadBanner from '../AppDownloadBanner'

const APP_BANNER_HEIGHT = 60
export const WRAPPER_ID = 'app-banner-wrapper'

/**
 * 앱 다운로드 배너와 헤더를 포함한 영역으로, children으로 전달된 헤더는 항상 sticky하게 유지되고
 * 앱 다운로드 배너는 스크롤에 따라 노출 및 숨김 처리된다.
 * @param children 앱 다운로드 배너 아래에 위치할 헤더 컴포넌트
 */
const AppDownloadBannerWrapper = ({ children }: PropsWithChildren) => {
  const divRef = useRef<HTMLDivElement>(null)
  const [lastScrollY, setLastScrollY] = useState(0)
  const [translateY, setTranslateY] = useState(0)
  const isAppBannerOpen = useAtomValue(appBannerAtom)
  const isClient = useIsClient()

  // 스크롤 함에 따라, translateY 값을 계산 및 업데이트
  const handleScroll = useCallback(() => {
    if (!divRef.current) return
    const currentScroll = window.scrollY
    const currentTranslateY = parseFloat(
      getComputedStyle(divRef.current).transform.split(',')[5],
    )

    if (lastScrollY <= 0 || currentScroll <= 0) {
      setTranslateY(0)
    }
    // 스크롤을 내릴 때
    else if (currentScroll > lastScrollY)
      setTranslateY(
        Math.max(
          -APP_BANNER_HEIGHT,
          currentTranslateY - (currentScroll - lastScrollY),
        ),
      )
    // 스크롤을 올릴 때
    else if (currentScroll < lastScrollY) {
      setTranslateY(
        Math.min(0, currentTranslateY + (lastScrollY - currentScroll)),
      )
    }

    setLastScrollY(currentScroll)
  }, [lastScrollY])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  // 앱 다운로드 배너를 노출하지 않을 때는 그냥 children만 반환
  // isIOS와 isSafari는 서버 측에서 무조건 false를 렌더링한다.
  // 따라서 배너가 나타났다가 사라지는 현상이 발생하므로 isClient를 확인하도록 한다.
  if (!isClient || isWebview() || (isIOS() && isSafari()) || !isAppBannerOpen)
    return <>{children}</>

  return (
    <div
      id={WRAPPER_ID}
      ref={divRef}
      className="sticky z-20 top-0"
      style={{ transform: `translateY(${translateY}px)` }}
    >
      <AppDownloadBanner />
      {children}
    </div>
  )
}

export default AppDownloadBannerWrapper
